
var CategoryPageView = function CategoryPageView(_ref) {
  var _structure$cookies, _structure$cookies$ac;
  var structure = _ref.structure;
  var siteId = structure == null ? void 0 : structure.id;
  var customerId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id;
  var CategoryData = structure == null ? void 0 : structure.route;
  window.rtbhEvents = window.rtbhEvents || [];
  if (siteId === 'category') {
    var _window;
    return (_window = window) == null ? void 0 : _window.rtbhEvents.push({
      eventType: 'category',
      categoryId: CategoryData.categoryId
    }, {
      eventType: 'uid',
      id: customerId !== null && customerId !== void 0 ? customerId : 'unknown'
    });
  }
  if (siteId === 'subcategory') {
    var _window2;
    return (_window2 = window) == null ? void 0 : _window2.rtbhEvents.push({
      eventType: 'category',
      categoryId: CategoryData.subCategoryId
    }, {
      eventType: 'uid',
      id: customerId !== null && customerId !== void 0 ? customerId : 'unknown'
    });
  }
  return null;
};
export default CategoryPageView;