import buildProduct from '../utils/buildProduct';
var addToCart = function addToCart() {
  var _window, _window$ttq;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    product = _ref.product;
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    eventId = _ref2.eventId;
  if (((_window = window) == null ? void 0 : (_window$ttq = _window.ttq) == null ? void 0 : _window$ttq.track) && product) {
    window.ttq.track('AddToCart', buildProduct(product), {
      event_id: eventId
    });
  }
};
export default addToCart;