import newsletterSuccess from './newsletterSuccess';
import pageView from './pageView';
import productDetailEvent from './productDetail';
import productAddCart from './productAddCart';
import productListClick from './productListClick';
var Stewie = {
  'addtocart:success': [productAddCart],
  'newsletter:success': [newsletterSuccess],
  pageview: [pageView, productDetailEvent],
  'productlist:click': [productListClick]
};
export default Stewie;