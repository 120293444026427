var pageView = function pageView() {
  var _structure$cookies, _structure$cookies$ac, _structure$cookies2, _structure$config, _window, _window$stewie;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    structure = _ref.structure;
  var accountId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id;
  var customerId = structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.customerId;
  var salesChannelId = (structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.salesChannelId) || '';
  var shouldPing = !customerId || accountId && customerId !== accountId;
  if (((_window = window) == null ? void 0 : (_window$stewie = _window.stewie) == null ? void 0 : _window$stewie.api) && shouldPing) {
    stewie.api.channel(salesChannelId);
    if (accountId) {
      stewie.api.uid(accountId);
    }
    stewie.api.ping();
    stewie.api.dispatch();
  }
};
export default pageView;