export var buildProductEvent = function buildProductEvent() {
  var _product$price, _product$installment;
  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    productCashPrice: product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.bestPrice,
    productId: product == null ? void 0 : product.variationId,
    productPrice: product == null ? void 0 : (_product$installment = product.installment) == null ? void 0 : _product$installment.totalAmount
  };
};
var productDetail = function productDetail() {
  var _window, _window$stewie;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$structure = _ref.structure,
    structure = _ref$structure === void 0 ? {} : _ref$structure,
    _ref$data = _ref.data,
    data = _ref$data === void 0 ? {} : _ref$data;
  var pageName = structure.name;
  var product = data.product;
  if (((_window = window) == null ? void 0 : (_window$stewie = _window.stewie) == null ? void 0 : _window$stewie.api) && pageName === 'produto') {
    var _structure$config;
    stewie.api.view(buildProductEvent(product));
    stewie.api.channel((structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.salesChannelId) || '');
    stewie.api.dispatch();
  }
};
export default productDetail;