import pdpClickWishlist from './pdpClickWishlist';
import addToCart from './addToCart';
import homePageView from './HomePageView';
import CategoryPageView from './CategoryPageView';
import promotionalPageView from './promotionalPageView';
import pdpPageView from './pdpPageView';
import Placebo from './placebo';
import searchPageView from './searchPageView';
var events = {
  'addtocart:success': [addToCart],
  pageview: [homePageView, CategoryPageView, promotionalPageView, searchPageView, pdpPageView, Placebo],
  'product:wishlist:click': [pdpClickWishlist]
};
export default events;