var searchPageView = function searchPageView(_ref) {
  var _structure$cookies, _structure$cookies$ac, _data$search, _data$search$products;
  var data = _ref.data,
    structure = _ref.structure;
  var siteId = structure == null ? void 0 : structure.id;
  var customerId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id;
  var productList = data == null ? void 0 : (_data$search = data.search) == null ? void 0 : (_data$search$products = _data$search.products) == null ? void 0 : _data$search$products.slice(0, 5);
  var productListIds = productList == null ? void 0 : productList.map(function (product) {
    return product.id;
  });
  window.rtbhEvents = window.rtbhEvents || [];
  if (siteId === 'search') {
    var _window;
    (_window = window) == null ? void 0 : _window.rtbhEvents.push({
      eventType: 'listing',
      offerIds: productListIds
    }, {
      eventType: 'uid',
      id: customerId !== null && customerId !== void 0 ? customerId : 'unknown'
    });
  }
};
export default searchPageView;