import getConfig from 'next/config';
import buildProduct from '../utils/buildProduct';
var addToCart = function addToCart() {
  var _window, _window$kwaiq;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    product = _ref.product;
  var _getConfig = getConfig(),
    publicRuntimeConfig = _getConfig.publicRuntimeConfig;
  var kwaiId = publicRuntimeConfig.kwaiId;
  if (((_window = window) == null ? void 0 : (_window$kwaiq = _window.kwaiq) == null ? void 0 : _window$kwaiq.instance) && kwaiId && product) {
    window.kwaiq.instance(kwaiId).track('addToCart', buildProduct(product));
  }
};
export default addToCart;