import { setCookie, getCookie, cookiesConstants, removeCookie } from '@magalu/mixer-utils';
import normalizeStoreName from './normalizeStoreName';
var ML2_SID = cookiesConstants.ML2_SID;
var pmdCookieCheckout = function pmdCookieCheckout(_ref, config) {
  var _structure$route, _structure$config, _config$partnerId, _structure$config2, _structure$config4;
  var structure = _ref.structure;
  var storeId = structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.storeId;
  var normalizedStoreName = normalizeStoreName(storeId, config == null ? void 0 : config.prefix);
  var cartId = getCookie(ML2_SID);
  var storeCartId = getCookie("".concat(ML2_SID, "_").concat(normalizedStoreName));
  setCookie({
    domain: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.cookieDomain,
    name: 'MLPARCEIRO',
    value: (_config$partnerId = config == null ? void 0 : config.partnerId) !== null && _config$partnerId !== void 0 ? _config$partnerId : 3440
  });
  setCookie({
    domain: structure == null ? void 0 : (_structure$config2 = structure.config) == null ? void 0 : _structure$config2.cookieDomain,
    name: (config == null ? void 0 : config.cookieShowcaseName) || 'mvc_showcase_name',
    value: normalizedStoreName
  });
  if (storeCartId === cartId) return;
  if (storeCartId) {
    var _structure$config3;
    setCookie({
      domain: structure == null ? void 0 : (_structure$config3 = structure.config) == null ? void 0 : _structure$config3.cookieDomain,
      name: ML2_SID,
      value: storeCartId
    });
    return;
  }
  removeCookie(ML2_SID, structure == null ? void 0 : (_structure$config4 = structure.config) == null ? void 0 : _structure$config4.cookieDomain, '/');
};
export default pmdCookieCheckout;