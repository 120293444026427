import { slug } from '@magalu/mixer-utils';
var buildProduct = function buildProduct(product) {
  var _product$category, _product$subcategory, _product$price, _product$price2, _product$price3, _product$price4;
  return {
    content_category: "".concat(slug(product == null ? void 0 : (_product$category = product.category) == null ? void 0 : _product$category.id), " - ").concat(slug(product == null ? void 0 : (_product$subcategory = product.subcategory) == null ? void 0 : _product$subcategory.id)),
    content_id: product == null ? void 0 : product.variationId,
    content_name: product == null ? void 0 : product.title,
    content_type: 'product',
    currency: 'BRL',
    price: (product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.bestPrice) || (product == null ? void 0 : (_product$price2 = product.price) == null ? void 0 : _product$price2.price),
    quantity: 1,
    value: (product == null ? void 0 : (_product$price3 = product.price) == null ? void 0 : _product$price3.bestPrice) || (product == null ? void 0 : (_product$price4 = product.price) == null ? void 0 : _product$price4.price)
  };
};
export default buildProduct;