import getConfig from 'next/config';
import buildProduct from '../utils/buildProduct';
var pageView = function pageView() {
  var _window, _window$kwaiq;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$data = _ref.data;
  _ref$data = _ref$data === void 0 ? {} : _ref$data;
  var product = _ref$data.product,
    structure = _ref.structure;
  var _getConfig = getConfig(),
    publicRuntimeConfig = _getConfig.publicRuntimeConfig;
  var kwaiId = publicRuntimeConfig.kwaiId;
  if (!((_window = window) == null ? void 0 : (_window$kwaiq = _window.kwaiq) == null ? void 0 : _window$kwaiq.instance) || !kwaiId) return;
  if ((structure == null ? void 0 : structure.id) === 'product') {
    window.kwaiq.instance(kwaiId).track('contentView', buildProduct(product));
  } else {
    window.kwaiq.instance(kwaiId).track('contentView');
  }
};
export default pageView;