var addToCart = function addToCart(_ref) {
  var _window;
  var clientId = _ref.clientId,
    product = _ref.product;
  var id = product.id;
  window.rtbhEvents = window.rtbhEvents || [];
  (_window = window) == null ? void 0 : _window.rtbhEvents.push({
    eventType: 'basketadd',
    offerId: id
  }, {
    eventType: 'uid',
    id: clientId || 'unknown'
  });
};
export default addToCart;