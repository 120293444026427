var PageView = function PageView(_ref) {
  var _structure$cookies, _structure$cookies$ac;
  var structure = _ref.structure;
  var customerId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id;
  if (typeof window.spdt === 'function') {
    window.spdt('alias', {
      id: customerId
    });
    window.spdt('view');
  }
};
export default PageView;