
import pmdCookieCheckout from './pmdCookieCheckout';
import buyButtonsClick from './buyButtonsClick';
import addToCart from './addToCart';
var events = {
  'addtocart:success': [addToCart],
  'buybuttons:click': [buyButtonsClick],
  pageview: [pmdCookieCheckout],
  'shoppingbag:click': [pmdCookieCheckout]
};
export default events;