var pdpClickWishlist = function pdpClickWishlist(_ref) {
  var _structure$cookies, _structure$cookies$ac, _window;
  var structure = _ref.structure,
    id = _ref.id;
  var customerId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id;
  var productId = id;
  window.rtbhEvents = window.rtbhEvents || [];
  (_window = window) == null ? void 0 : _window.rtbhEvents.push({
    eventType: 'wishlist',
    offerId: productId
  }, {
    eventType: 'uid',
    id: customerId !== null && customerId !== void 0 ? customerId : 'unknown'
  });
};
export default pdpClickWishlist;