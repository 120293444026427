
import pmeCookieCheckout from './pmeCookieCheckout';
import buyButtonsClick from './buyButtonsClick';
import addToCart from './addToCart';
var events = {
  'addtocart:success': [addToCart],
  'buybuttons:click': [buyButtonsClick],
  pageview: [pmeCookieCheckout],
  'shoppingbag:click': [pmeCookieCheckout]
};
export default events;