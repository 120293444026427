import { setCookie, cookiesConstants, getCookie } from '@magalu/mixer-utils';
import normalizeStoreName from './normalizeStoreName';
var ML2_SID = cookiesConstants.ML2_SID;
var addToCart = function addToCart(_ref, _ref2, config) {
  var _structure$route, _structure$config, _structure$config2;
  var cartId = _ref.cartId;
  var structure = _ref2.structure;
  var storeId = structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.storeId;
  var normalizedStoreName = normalizeStoreName(storeId, config == null ? void 0 : config.prefix);
  var cartIdCookie = getCookie(ML2_SID);
  var storeCartId = getCookie("".concat(ML2_SID, "_").concat(normalizedStoreName));
  if (storeCartId === cartId && cartIdCookie === cartId) return;
  setCookie({
    domain: structure == null ? void 0 : (_structure$config = structure.config) == null ? void 0 : _structure$config.cookieDomain,
    name: ML2_SID,
    value: cartId
  });
  setCookie({
    domain: structure == null ? void 0 : (_structure$config2 = structure.config) == null ? void 0 : _structure$config2.cookieDomain,
    name: "".concat(ML2_SID, "_").concat(normalizedStoreName),
    value: cartId
  });
};
export default addToCart;