var HomePageView = function HomePageView(_ref) {
  var _structure$cookies, _structure$cookies$ac;
  var structure = _ref.structure;
  var siteId = structure == null ? void 0 : structure.id;
  var customerId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id;
  window.rtbhEvents = window.rtbhEvents || [];
  if (siteId === 'home') {
    var _window;
    (_window = window) == null ? void 0 : _window.rtbhEvents.push({
      eventType: 'home'
    }, {
      eventType: 'uid',
      id: customerId !== null && customerId !== void 0 ? customerId : 'unknown'
    });
  }
};
export default HomePageView;