var pdpPageView = function pdpPageView(_ref) {
  var _structure$cookies, _structure$cookies$ac, _data$product;
  var data = _ref.data,
    structure = _ref.structure;
  var siteId = structure == null ? void 0 : structure.id;
  var customerId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.customer_id;
  var productId = data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.id;
  window.rtbhEvents = window.rtbhEvents || [];
  if (siteId === 'product') {
    var _window;
    (_window = window) == null ? void 0 : _window.rtbhEvents.push({
      eventType: 'offer',
      offerId: productId
    }, {
      eventType: 'uid',
      id: customerId !== null && customerId !== void 0 ? customerId : 'unknown'
    });
  }
};
export default pdpPageView;