export var buildProductEvent = function buildProductEvent() {
  var _product$price, _product$price2, _product$seller;
  var product = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    price: product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.fullPrice,
    productCashPrice: product == null ? void 0 : (_product$price2 = product.price) == null ? void 0 : _product$price2.bestPrice,
    productId: product == null ? void 0 : product.variationId,
    sellerId: product == null ? void 0 : (_product$seller = product.seller) == null ? void 0 : _product$seller.id
  };
};
var productAddCart = function productAddCart() {
  var _window, _window$stewie;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$product = _ref.product,
    product = _ref$product === void 0 ? {} : _ref$product,
    _ref$channel = _ref.channel,
    channel = _ref$channel === void 0 ? '' : _ref$channel;
  if ((_window = window) == null ? void 0 : (_window$stewie = _window.stewie) == null ? void 0 : _window$stewie.api) {
    stewie.api.channel(channel);
    stewie.api.cart([buildProductEvent(product)]);
    stewie.api.dispatch();
  }
};
export default productAddCart;