import { slug } from '@magalu/mixer-utils';
var buildProduct = function buildProduct(product) {
  var _product$price, _product$price2, _product$category, _product$subcategory;
  var productPrice = Number((product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.bestPrice) || (product == null ? void 0 : (_product$price2 = product.price) == null ? void 0 : _product$price2.price));
  return {
    content_type: 'product',
    contents: [{
      content_category: "".concat(slug(product == null ? void 0 : (_product$category = product.category) == null ? void 0 : _product$category.id), " - ").concat(slug(product == null ? void 0 : (_product$subcategory = product.subcategory) == null ? void 0 : _product$subcategory.id)),
      content_id: product == null ? void 0 : product.variationId,
      content_name: product == null ? void 0 : product.title,
      price: productPrice,
      quantity: 1
    }],
    currency: 'BRL',
    value: productPrice
  };
};
export default buildProduct;