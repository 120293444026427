var callSpdt = function callSpdt(data) {
  if (typeof window.spdt === 'function') {
    var _data$product, _data$product2, _data$product3, _data$product3$catego, _data$product4, _data$product4$seller, _data$product5, _data$product5$price;
    window.spdt('product', {
      currency: 'BRL',
      product_id: data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.id,
      product_name: data == null ? void 0 : (_data$product2 = data.product) == null ? void 0 : _data$product2.title,
      product_type: data == null ? void 0 : (_data$product3 = data.product) == null ? void 0 : (_data$product3$catego = _data$product3.category) == null ? void 0 : _data$product3$catego.id,
      product_vendor: data == null ? void 0 : (_data$product4 = data.product) == null ? void 0 : (_data$product4$seller = _data$product4.seller) == null ? void 0 : _data$product4$seller.id,
      value: data == null ? void 0 : (_data$product5 = data.product) == null ? void 0 : (_data$product5$price = _data$product5.price) == null ? void 0 : _data$product5$price.bestPrice
    });
  }
};
var ProductView = function ProductView(_ref) {
  var data = _ref.data,
    structure = _ref.structure;
  if ((structure == null ? void 0 : structure.id) === 'product') {
    callSpdt(data);
  }
  return null;
};
export default ProductView;