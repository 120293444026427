var NewsletterSuccess = function NewsletterSuccess() {
  var _structure$cookies, _structure$cookies$ac, _structure$cookies2, _window, _window$stewie;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    structure = _ref.structure;
  var accountId = structure == null ? void 0 : (_structure$cookies = structure.cookies) == null ? void 0 : (_structure$cookies$ac = _structure$cookies.accountData) == null ? void 0 : _structure$cookies$ac.id;
  var customerId = structure == null ? void 0 : (_structure$cookies2 = structure.cookies) == null ? void 0 : _structure$cookies2.customerId;
  if ((_window = window) == null ? void 0 : (_window$stewie = _window.stewie) == null ? void 0 : _window$stewie.api) {
    var userId = accountId || customerId;
    stewie.api.uid(userId);
    stewie.api.sendUserInformation({
      origin: data == null ? void 0 : data.origin,
      userInformation: data == null ? void 0 : data.email,
      userName: data == null ? void 0 : data.name
    });
    stewie.api.dispatch();
  }
};
export default NewsletterSuccess;