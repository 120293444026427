var addToCart = function addToCart(_ref) {
  var _window, _product$category, _product$seller, _product$price;
  var product = _ref.product;
  (_window = window) == null ? void 0 : _window.spdt == null ? void 0 : _window.spdt('addtocart', {
    currency: 'BRL',
    product_id: product == null ? void 0 : product.id,
    product_name: product == null ? void 0 : product.title,
    product_type: product == null ? void 0 : (_product$category = product.category) == null ? void 0 : _product$category.id,
    product_vendor: product == null ? void 0 : (_product$seller = product.seller) == null ? void 0 : _product$seller.category,
    quantity: 1,
    value: product == null ? void 0 : (_product$price = product.price) == null ? void 0 : _product$price.bestPrice
  });
};
export default addToCart;