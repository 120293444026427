var addToWishList = function addToWishList(_ref) {
  var _window;
  var id = _ref.id,
    price = _ref.price;
  (_window = window) == null ? void 0 : _window.spdt == null ? void 0 : _window.spdt('lead', {
    category: 'Add to Wish List',
    currency: 'BRL',
    type: id,
    value: price == null ? void 0 : price.bestPrice
  });
};
export default addToWishList;